<template>
  <AppCard
    :card-class="{ active: model.isActive, 'not-configured': !model.isConfigured }"
    :title="model.title"
    :title-route="toEditFormRoute"
    title-class="text-15-700 text-black q-link ellipsis col"
    body-default-class="q-pa-none"
    header-color="bg-grey-3"
    bordered
    label-tooltip
  >
    <template #titleRight>
      <q-icon
        v-if="!model.isConfigured"
        name="fa-solid fa-circle-info"
        color="negative"
        class="cursor-pointer"
      >
        <q-tooltip
          transition-hide="fade"
          transition-show="fade"
          content-class="bg-black text-13-400 q-px-lg q-py-md text-center"
          max-width="250px"
          anchor="bottom right"
          :offset="[-100, 30]"
        >
          {{ $t('text.notConfiguredForm') }}
        </q-tooltip>
      </q-icon>
      <q-icon
        name="fa-solid fa-ellipsis-vertical"
        class="cursor-pointer"
      >
        <ContextMenu>
          <ContextMenuButton
            :label="$t('button.editForm')"
            :to="toEditFormRoute"
            icon-left="fl:set"
          />
          <ContextMenuButton
            :label="$t('button.preview')"
            :href="$router.resolve(toPreviewFormRoute).href"
            icon-left="fl:eye_light"
            target="_blank"
          />
          <ContextMenuButton
            v-if="canCopyFormEmbedCode"
            :label="$t('button.copyCode')"
            icon-left="fl:duplicate"
            @click="copyCode(code)"
          />
          <ContextMenuButton
            v-if="canCopyFormEmbedCode && model.thankYouPageWidgets"
            :label="$t('button.copyThankYouCode')"
            icon-left="fl:duplicate"
            @click="copyCode(thankYouCode)"
          />
          <ContextMenuButton
            :label="$t('button.clone')"
            icon-left="fl:duplicate"
            @click="clone"
          />
          <ContextMenuButton
            :label="$t('button.resetStatistic')"
            icon-left="fl:close_ring"
            @click="resetStatistic"
          />
          <ContextMenuButton
            :label="$t('button.moveToCategory')"
            icon-left="fl:folder_send_light"
            @click="showMoveDialog = true"
          />
          <ContextMenuButton
            :label="$t('button.delete')"
            icon-left="fl:trash"
            @click="showRemoveDialog = true"
          />
        </ContextMenu>
      </q-icon>
    </template>
    <div class="q-px-lg q-py-md">
      <div class="row q-pb-md border-bottom">
        <div class="col-6 q-col-gutter-y-sm">
          <div class="q-col-gutter-x-xs">
            <q-icon
              name="fl:chat_fill"
              color="primary"
              size="16px"
            />
            <span class="text-12-700">{{ model.smsSent }}</span>
            <span class="text-12-400 text-grey">{{ $t('label.smsSent') }}</span>
          </div>
          <div class="q-col-gutter-x-xs">
            <q-icon
              name="fl:eye_fill"
              color="grey-2"
              size="16px"
            />
            <span class="text-12-700">{{ model.statistic.viewsCount }}</span>
            <span class="text-12-400 text-grey">{{ $t('label.views') }}</span>
          </div>
          <div class="q-col-gutter-x-xs">
            <q-icon
              name="fl:flag_fill"
              color="grey-2"
              size="16px"
            />
            <span class="text-12-700">{{ model.statistic.leadsCount }}</span>
            <span class="text-12-400 text-grey">{{ $t('label.dispatches') }}</span>
          </div>
        </div>
        <div
          class="percent col-6 text-20-700 bg-grey-3 border-radius-14 items-center flex justify-center q-col-gutter-x-xs"
        >
          <q-icon
            name="fl:filter_fill"
            color="primary"
          />
          <span>{{ model.statistic.conversion }}%</span>
        </div>
      </div>
      <AppToggle
        v-model="model.isActive"
        :label="$t('label.formActive')"
        class="q-pt-md form-toggle"
        @input="onChangeStatus"
      />
    </div>

    <AttentionDialog
      :is-open="showRemoveDialog"
      :title="$t('title.attention')"
      @close="showRemoveDialog = false"
    >
      <div class="row justify-center">
        <div class="col-8">
          <div class="text-19-400 text-center q-py-lg">
            {{ $t('text.deleteFormConfirm') }}
          </div>
        </div>
      </div>
      <template #footer>
        <AppButton
          v-close-popup
          class="col-4"
          :label="$t('button.no')"
        />
        <AppButton
          v-close-popup
          icon-right="fl:trash"
          class="col"
          color="white"
          text-color="black"
          :label="$t('button.delete')"
          outline
          @click="remove"
        />
      </template>
    </AttentionDialog>
    <MoveDialog
      v-if="showMoveDialog"
      :folder-type="ENUMS.FOLDER_TYPES.FORM"
      :entry="model"
      @move="onMove"
      @close="showMoveDialog = false"
    />
  </AppCard>
</template>

<script>
import AppCard from 'common/components/AppCard';
import AppToggle from 'common/components/AppToggle';
import AppButton from 'common/components/buttons/AppButton';
import ContextMenu from 'common/components/contextMenu/ContextMenu';
import ContextMenuButton from 'common/components/contextMenu/ContextMenuButton';
import AttentionDialog from 'common/components/dialogs/AttentionDialog';
import MoveDialog from 'common/components/dialogs/MoveDialog';
import handleError from 'common/helpers/handleError';
import { notifySuccess } from 'common/helpers/notify';
import { copyCodeMixin } from 'common/mixins';
import formCode from 'src/helpers/formCode';
import { mapActions } from 'vuex';

export default {
  name: 'FormCardPreview',
  components: {
    AppButton,
    AppCard,
    AppToggle,
    AttentionDialog,
    ContextMenu,
    ContextMenuButton,
    MoveDialog,
  },
  mixins: [copyCodeMixin],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: undefined,
      showMoveDialog: false,
      showRemoveDialog: false,
    };
  },
  computed: {
    toEditFormRoute() {
      return {
        name: ENUMS.ROUTES.FORM.INDEX,
        params: {
          guid: this.model.guid,
        },
      };
    },
    toPreviewFormRoute() {
      return {
        name: ENUMS.ROUTES.FORM.PREVIEW,
        params: {
          guid: this.model.guid,
        },
      };
    },
    code() {
      return formCode(this.model.guid);
    },
    thankYouCode() {
      return formCode(this.model.guid, true);
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.model = { ...newValue };
      },
    },
  },
  methods: {
    ...mapActions('dashboard', ['updateStatusForm', 'removeForm', 'fetchForms']),
    async onChangeStatus(status) {
      try {
        this.$q.loading.show();
        await this.updateStatusForm({
          guid: this.model.guid,
          body: { status },
        });
      } catch (error) {
        handleError(error);
      } finally {
        this.$q.loading.hide();
      }
    },
    async remove() {
      try {
        this.$q.loading.show();
        const { title, guid } = this.model;
        await this.removeForm(guid);
        notifySuccess(this.$t('notification.formRemoved', { name: title }));
      } catch (error) {
        handleError(error);
      } finally {
        this.$q.loading.hide();
      }
    },
    async clone() {
      try {
        this.$q.loading.show();
        await this.$axios.post(`forms/${this.model.guid}/duplicate`);
        await this.fetchForms();
      } catch (error) {
        handleError(error);
      } finally {
        this.$q.loading.hide();
      }
    },
    async resetStatistic() {
      try {
        this.$q.loading.show();
        await this.$axios.post(`forms/${this.model.guid}/reset-statistic`);
        await this.fetchForms();
      } catch (error) {
        handleError(error);
      } finally {
        this.$q.loading.hide();
      }
    },
    onMove() {
      this.fetchForms();
      this.showMoveDialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .q-card {
  border-radius: 20px;
  transition: all 0.5s ease;

  &.not-configured {
    border-color: $negative;
  }

  &:not(.active) {
    background: $grey-3;

    .percent {
      border-color: $grey-2;
    }
  }

  .percent {
    transition: all 0.5s ease;
    border: 1px solid transparent;
  }

  .form-toggle {
    .q-toggle__track,
    .q-toggle__inner {
      height: 20px;
      min-width: 26px;
      width: 26px;
    }

    .q-toggle__thumb {
      width: 16px;
      height: 16px;
      top: 2px;
      left: 2px;
    }

    .q-toggle__inner--truthy {
      .q-toggle__thumb {
        right: 2px;
        left: unset;
      }
    }
  }
}
</style>
