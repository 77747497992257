import { render, staticRenderFns } from "./FormCardPreview.vue?vue&type=template&id=5781e5cf&scoped=true&"
import script from "./FormCardPreview.vue?vue&type=script&lang=js&"
export * from "./FormCardPreview.vue?vue&type=script&lang=js&"
import style0 from "./FormCardPreview.vue?vue&type=style&index=0&id=5781e5cf&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5781e5cf",
  null
  
)

export default component.exports
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QIcon,QTooltip,QCard,QToggle});qInstall(component, 'directives', {ClosePopup});
