<template>
  <AppCard
    class="full-height"
    :bordered="isActiveAccount && isEmptyFormList"
  >
    <div :class="['flex row q-col-gutter-lg', { 'q-pa-xl full-height items-center justify-center': !isActiveAccount || isEmptyFormList }]">
      <template v-if="isActiveAccount">
        <AppButtonLink
          v-if="isEmptyFormList"
          stack
          @click="openNewForm"
        >
          <div class="text-center">
            <q-img
              src="~/assets/chat_plus_big.svg"
              width="190px"
            />
          </div>
          <div class="text-center text-13-400 text-dark">
            {{ $t('text.createFirstForm') }}
          </div>
        </AppButtonLink>
        <template v-else>
          <div class="col-12">
            <FolderSelector
              :type="ENUMS.FOLDER_TYPES.FORM"
              @fetch="fetchForms"
            />
          </div>
          <div class="col-12 col-sm-6 col-lg-4 col-xl-3">
            <AppButtonLink
              class="fit add-form-btn"
              stack
              @click="openNewForm"
            >
              <div class="text-center">
                <q-img
                  src="~/assets/chat_plus_big.svg"
                  width="64px"
                />
              </div>
              <div class="text-center text-16-400 text-dark">
                {{ $t('text.createFirstForm') }}
              </div>
            </AppButtonLink>
          </div>
          <FormCardPreview
            v-for="(form, index) in forms"
            :key="form.guid"
            v-model="forms[index]"
            class="form-card col-12 col-sm-6 col-lg-4 col-xl-3"
          />
        </template>
      </template>
      <div
        v-else
        class="expired-block text-center"
      >
        <div class="img">
          <q-img
            src="~/assets/low_meter.svg"
            width="214px"
          />
        </div>
        <div class="expired-block-desc q-mt-lg">
          <div>
            <div class="title q-mx-auto">{{ $t('text.sorryPaidPlanExpired') }}</div>
          </div>
          <div class="q-mt-md">
            <div class="hint q-mx-auto text-grey text-16-400">{{ $t('text.extendTariffToUseService') }}</div>
          </div>
        </div>
        <AppButton
          :label="$t('button.extend')"
          :loading="loading"
          class="q-mt-lg"
          text-color="black"
          icon-right="fa fa-plus"
          padding="11px 20px"
          @click="goToPayPage"
        />
      </div>
    </div>
  </AppCard>
</template>

<script>
import AppCard from 'common/components/AppCard';
import FolderSelector from 'common/components/FolderSelector';
import AppButton from 'common/components/buttons/AppButton';
import AppButtonLink from 'common/components/buttons/AppButtonLink';
import handleError from 'common/helpers/handleError';
import FormCardPreview from 'pages/dashboard/components/FormCardPreview';
import { createFormMixin } from 'src/mixins';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'DashboardPage',
  components: {
    FolderSelector,
    FormCardPreview,
    AppButton,
    AppButtonLink,
    AppCard,
  },
  mixins: [createFormMixin],
  data() {
    return {
      updateInterval: undefined,
      loading: false,
      isFiltered: false,
    };
  },
  computed: {
    ...mapGetters('app', ['getProducts']),
    ...mapState('dashboard', ['forms']),
    ...mapGetters('dashboard', ['getSelectedFolders']),
    selectedFolders() {
      return this.getSelectedFolders(ENUMS.FOLDER_TYPES.FORM);
    },
    isActiveAccount() {
      return this.$store.getters['app/isActiveAccount'];
    },
    isEmptyFormList() {
      return this.forms.length === 0 && !this.isFiltered;
    },
  },
  watch: {
    isActiveAccount() {
      this.fetch();
    },
    selectedFolders: {
      deep: true,
      handler() {
        this.isFiltered = Array.isArray(this.selectedFolders) && this.selectedFolders.length > 0;
        this.fetch();
      },
    },
  },
  created() {
    this.fetch();
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
  },
  methods: {
    ...mapActions('dashboard', ['fetchForms']),
    ...mapActions('app', ['identity']),
    async fetch() {
      if (!this.isActiveAccount) {
        return;
      }
      try {
        // For cases when fetching forms after log in
        await this.$nextTick();
        this.$q.loading.show();
        await this.fetchForms();
        if (this.updateInterval) {
          clearInterval(this.updateInterval);
          this.loading = false;
        }
      } catch (error) {
        handleError(error);
      } finally {
        this.$q.loading.hide();
      }
    },
    goToPayPage() {
      const subs = this.getProducts('main');
      if (!subs) {
        return;
      }
      window.open(subs.url, '_blank');
      this.updateInterval = setInterval(this.identity, 3000);
      this.loading = true;
    },
  },
};
</script>

<style scoped lang="scss">
.add-form-btn {
  min-height: 220px;
}
.expired-block {
  width: 100%;
  &-desc {
    .title {
      font-size: 37px;
      font-weight: 700;
      width: 300px;
    }
    .hint {
      width: 150px;
    }
  }
}
</style>
